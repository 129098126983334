<template>
  <div class="top_content" :style="{ color: `${colorData}` }">
    本系统是预报名，由主办方基金会最终审核和决定参与人选
  </div>
</template>

<script >
export default {
  props: {
    colorData: {
      type: String,
      default: "",
    },
  },
};
</script>

<style >
.top_content {
  font-size: 12px;
  text-align: center;
}
</style>